<template>
  <section class="text-center text-sm-left">
    <!-- titulo -->
    <div class="my-6 d-flex flex-column">
      <span
        class="text-h5 secondary--text"
        v-text="'Detalle de solicitud de necesidades'"
      />
      <span v-text="'Ejercicio ' + anioFiscal" />
    </div>

    <!-- Detalle solicitud -->
    <div
      class="d-flex align-center flex-column flex-sm-row justify-sm-space-between my-6"
    >
      <div class="d-flex flex-column flex-sm-row mb-3 mb-sm-0" v-if="this.haveRole('ROLE_UNIDAD_CONSOLIDADORA')">
        <span class="mr-sm-2" v-text="'Unidad:'" />
        <span
          class="ml-sm-2 secondary--text"
          v-text="unidad_solict_var?.nombre"
        />
      </div>
      <div class="d-flex flex-column flex-sm-row mb-3 mb-sm-0">
        <span class="mr-sm-2" v-text="'OBS:'" />
        <span
          class="ml-sm-2 secondary--text"
          v-text="solicitud_detail_obs_var?.nombre"
        />
      </div>
      <div class="d-flex flex-column text-center">
        <span class="secondary--text text-h5" v-text="MontoTotalSolicitudCmp" />
        <span class="" v-text="'Monto total solicitado:'" />
      </div>
    </div>

    <!-- container -->
    <div>
      <span v-text="'Fuentes de financiamiento'" />
      <v-data-table
        :headers="HEADER_CONST"
        :items="solicitud_financt_unit_var"
      >
        <!-- Slot monto asignado -->
        <template v-slot:[`item.FondosSolicitudNecesidad.monto`]="{ item }">
          {{
            Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }).format(item.FondosSolicitudNecesidad.monto)
          }}
        </template>
      </v-data-table>
    </div>
    <footer
      tile
      class="py-4 mt-8 d-flex flex-column flex-sm-row"
      color="bgMinsal"
    >
      <v-btn
        class="button-extra text-capitalize mt-4 mt-sm-0 mx-8 mx-sm-4"
        color="secondary"
        outlined
        @click="$router.back()"
      >
        Volver
      </v-btn>
      <div v-if="id_estado_solicitud_var == 1">
        <v-btn
          class="button-extra text-capitalize mt-4 mt-sm-0 mx-8 mx-sm-4"
          color="error"
          outlined
          @click="show_dialog_rechazar_var = true"
        >
          Rechazar
        </v-btn>
        <v-btn
          class="button-extra text-capitalize mt-4 mt-sm-0 mx-8 mx-sm-4"
          color="success"
          outlined
          @click="show_dialog_aprobar_var = true"
        >
          Aprobar
        </v-btn>
      </div>
    </footer>
    <confirmation-dialog-component
      :show="show_dialog_aprobar_var"
      title="¿Deseas aprobar la solicitud?"
      message="De confirmarse la siguiente acción, los cambios no serán reversibles."
      btnConfirmar="Aceptar"
      @close="show_dialog_aprobar_var = false"
      @confirm="AprobarSolicitudFtn"
    />
    <confirmation-dialog-component
      :show="show_dialog_rechazar_var"
      title="¿Deseas rechazar la solicitud?"
      message="De confirmarse la siguiente acción, los cambios no serán reversibles."
      btnConfirmar="Aceptar"
      @close="show_dialog_rechazar_var = false"
      @confirm="RechazarSolicitudFtn"
    />
  </section>
</template>

<script>
// importacion de componentes globales
import ConfirmationDialogComponent from "../../../components/ConfirmationDialogComponent.vue";

// importacion de librerias
import { mapState } from "vuex";

export default {
  components: {
    ConfirmationDialogComponent,
  },

  data: () => ({
    // constantes
    HEADER_CONST: [
      {
        align: "left",
        text: "Código presupuestario",
        value: "Fondo.cifrado_presupuestario",
      },
      {
        align: "left",
        text: "Monto ($)",
        value: "FondosSolicitudNecesidad.monto",
      },
    ],
    // variables
    id_solicitud_var: null,
    id_estado_solicitud_var: null,
    solicitud_financt_unit_var: [],
    solicitud_detail_obs_var: {},
    show_dialog_aprobar_var: false,
    show_dialog_rechazar_var: false,
    unidad_solict_var: {},
  }),

  computed: {
    // mapeo de estados
    ...mapState(["userInfo", "anioFiscal"]),

    MontoTotalSolicitudCmp() {
      let monto_total = 0;
      this.solicitud_financt_unit_var.forEach((element) => {
        monto_total += Number(element.FondosSolicitudNecesidad.monto);
      });

      return Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(monto_total);
    },
  },

  methods: {
    // Funciones asincronas
    //  Funcion que realiza una peticion al backend para obtener los detalles de una solicitud de necesidad ( API )
    async FetchDetailSolicitudNecesidadFtn() {

      const response = await this.services.PacServices.getSolicitudPAC(
        this.id_solicitud_var
      );

      // asignacion de variables
      if (response) {
        this.id_estado_solicitud_var = Number(
          response?.data?.EstadoSolicitudNecesidad?.id
        );
        console.log('response.data :>> ', response.data);
        this.solicitud_financt_unit_var = response?.data?.FinanciamientoUnidads;
        this.solicitud_detail_obs_var = response?.data?.Ob;
        this.unidad_solict_var = response?.data?.Unidad;
      }

    },

    //  Funcion que realiza la peticion de aprobacion de solicitud de necesidad ( API )
    async AprobarSolicitudFtn() {
      const response = await this.services.PacServices.putEstadoSolicitud(
        this.id_solicitud_var,
        {
          id_estado: 2,
        }
      );

      if (response) {
        this.temporalAlert({
          message: "Solicitud aprobada correctamente",
          show: true,
          type: "success",
        });

        this.$router.back();
      }
    },

    //  Funcion que realiza la peticion de rechazo de solicitud de necesidad ( API )
    async RechazarSolicitudFtn() {
      const response = await this.services.PacServices.putEstadoSolicitud(
        this.id_solicitud_var,
        {
          id_estado: 3,
        }
      );

      if (response) {
        this.temporalAlert({
          message: "Solicitud rechazada correctamente",
          show: true,
          type: "success",
        });

        this.$router.back();
      }
    },
  },

  created() {
    this.id_solicitud_var = Number(this.$route.params.id_solicitud);
    this.FetchDetailSolicitudNecesidadFtn();
  },
};
</script>